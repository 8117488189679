import './wallet.css'
import React, {useEffect, useState} from "react";
import {ethers} from "ethers";
import {
    exchangeContractAddress,
    tokenContractAbi, tokenContractAddress,
    usdtContractAbi,
    usdtContractAddress
} from "../../helpers/contracts";
import {Button, Col, Modal, Row} from "react-bootstrap";


function Wallet(props) {
    const [sttBalance, setSttBalance] = useState(0)
    const [usdtBalance, setUsdtBalance] = useState(0)
    const [etcBalance, setEthBalance] = useState(0)
    const [helpUsdtBalance, setHelpUsdtBalance] = useState(0)
    const [showWwModal, setWwModal] = useState(false)

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    useEffect(() => {
        if (props.account) {
            const acc = props.account
            const provider_balance = new ethers.BrowserProvider(window.ethereum)
            const contract = new ethers.Contract(tokenContractAddress, tokenContractAbi, provider_balance);
            const usdtContract = new ethers.Contract(usdtContractAddress, usdtContractAbi, provider_balance);
            let totalSt = 0.0
            let totalUs = 0.0
            contract.totalSupply().then(res => {
                totalSt = (Number(res) / Math.pow(10, 9)).toFixed(2)
                usdtContract.balanceOf(exchangeContractAddress).then(res => {
                    totalUs = (Number(res) / Math.pow(10, 6)).toFixed(2)
                    contract.balanceOf(acc).then(res => {
                        let stBalance = (parseFloat((Number(res) / Math.pow(10, 9)) - 0.01).toFixed(2))
                        if (stBalance < 0) {
                            stBalance = 0.0
                        }
                        let rate = totalUs / totalSt
                        let usBalance = (parseInt(parseFloat((rate * stBalance)) * 100) /100)
                        setSttBalance(stBalance)
                        setHelpUsdtBalance(usBalance)

                    })
                })
            })
            usdtContract.balanceOf(acc).then(res => {
                let walletUsdtBalance = (parseInt(parseFloat(Number(res) / Math.pow(10, 6)) * 100) / 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ').replace('.', ',')
                setUsdtBalance(walletUsdtBalance)
            })
            provider_balance.getBalance(acc).then((balance) => {
                const balanceInEth = parseFloat(ethers.formatEther(balance)).toFixed(5).toString().replace('.', ',')
                setEthBalance(balanceInEth)
            })
        }
    }, [props.account]);

    return (
        <React.Fragment>
            <div className={"wallet_card eth-card"}>
                <div className={"wallet_card-stripe"}>
                    <span>{props.account ? '****' + props.account.substr(props.account.length - 4) : ''}</span>
                </div>
                {props.account
                    ? <a className={"wallet_card-balance"} href={"https://arbiscan.io/address/" + props.account} target={"_blank"} rel={"noreferrer"}><span className={"stt-empty"}></span><span className={"stt-balance"}>{numberWithCommas(sttBalance)}</span><span className={"stt-arrow"}><img src={"/img/arrow-dark.png"} alt={''} style={{width: 35}} /></span></a>
                    : <React.Fragment><a className={"wallet_card-balance"} href={"#!"} onClick={() => setWwModal(true)}><span className={"stt-empty"}></span><span
                        className={"stt-balance"}>0.0</span><span className={"stt-arrow"}><img
                        src={"/img/arrow-dark.png"} alt={''} style={{width: 35}}/></span></a>
                        <Modal size="sm" show={showWwModal} onHide={() => setWwModal(false)} aria-labelledby="info-mod-title" className={"pre-form-modal telegram-modal"} centered >
                            <Modal.Body>
                                <div className={"stt_modal_header"}>


                                </div>
                                <div className={"help-wrapper telegram-wrapper"} style={{paddingLeft: 0, paddingRight: 0}}>
                                    <img src={"/img/lock.png"} alt={''} style={{width: 80, marginTop: 70}} />
                                    <p style={{fontSize: '1.1rem', marginBottom: 20, marginTop: 30, color: '#888888'}}>Authorization</p>
                                    <p style={{fontSize: '.8rem', marginTop: 0, fontWeight: 400, marginBottom: 25}}>To use full functionality, login to the<br/>website with the browser of your crypto<br/>wallet</p>
                                    <a href={"/login.pdf"} target={"_blank"} rel={"noreferrer"} style={{fontSize: '.8rem', marginTop: 0, fontWeight: 500, display: "block", marginBottom: 45, color: '#47c999'}}>Instructions</a>

                                    <Button className="modal-button"  onClick={() => setWwModal(false)}>Ok</Button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </React.Fragment>
                }
                <p className={"help-usdt-balance"}>~ {numberWithCommas(helpUsdtBalance)} USDT</p>
                <div className={"additional-balances"}>
                    <div><span>USDT</span> {numberWithCommas(usdtBalance)}</div>
                    <div><span>ETH</span> {etcBalance}</div>
                </div>
            </div>
            <Row>
                <Col xs={4}>
                    <a className={"wallet_link  eth-card"} href={"https://t.me/P2PSTT"} target={"_blank"} rel={"noreferrer"}>
                        <span>P2P</span>
                        <span><img src={"/img/arrow-dark.png"} alt={''} style={{width: 35}} /></span>
                    </a>
                </Col>
                <Col xs={8}>
                    <a className={"wallet_link  eth-card"} href={"https://t.me/tastyday_stt_purchase_bot"} target={"_blank"} rel={"noreferrer"}>
                        <span>Swap bot</span>
                        <span><img src={"/img/arrow-dark.png"} alt={''} style={{width: 35}} /></span>
                    </a>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Wallet;